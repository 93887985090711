import React from "react"

import Infopage from "../components/templates/Infopage"
import SEO from "../components/atoms/Seo"

const NotFoundPage = () => {
  const title = 'NOT FOUND';
  const leadText = 'You just hit a route that doesn\'t exist... the sadness.';
  const bodyText = () => {
      return (
          <>
              <p>This page has gone away!<br /> 
              If you're sure this should work 
              please contact page administrator at <a href="mailto:mathias@grahlie.se?subject=A GAME ABOVE, missing page" style={{color: '#fff'}}>here</a></p>
          </>
      )
  }

  return (
    <Infopage title={title} headerColor='Blue' leadText={leadText} bodyText={bodyText()} icon="spaceship">
      <SEO title="404: Not found" />
    </Infopage>
  )
}

export default NotFoundPage
